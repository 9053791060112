import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import RoleBasedRoute from './routesConstants';
import { rolesApp } from 'src/utils/rolesConstants';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management:
const Events = Loader(lazy(() => import('src/content/management/Events')));
const CreateEvent = Loader(lazy(() => import('src/content/management/Events/create')));
const EditEvent = Loader(lazy(() => import('src/content/management/Events/edit')));
const Tickets = Loader(lazy(() => import('src/content/management/Events/tickets')));
const CreateTicket = Loader(lazy(() => import('src/content/management/Events/tickets/create')));
const ManagementTicketsRedimidor = Loader(lazy(() => import('src/content/management/Events/tickets/ticketsRedimidors')));
const Sell = Loader(lazy(() => import('src/content/management/Events/tickets/sell')));
const SellTicket = Loader(lazy(() => import('src/content/management/Events/tickets/sellTickets')));
const SellCompleted = Loader(lazy(() => import('src/content/management/Events/tickets/sellCompleted')));
const TicketsTypes = Loader(lazy(() => import('src/content/management/Events/ticketsTypes')));
const CreateTicketTypes = Loader(lazy(() => import('src/content/management/Events/ticketsTypes/create')));
const StatisticsEvent = Loader(lazy(() => import('src/content/management/Events/statistics')));
const StatisticsEventDirectRRPP = Loader(lazy(() => import('src/content/management/Events/statistics/directRrpp')));
const StatisticsEventProducer = Loader(lazy(() => import('src/content/management/Events/statistics/producer')));
const StatisticsEventReferencedRRPP = Loader(lazy(() => import('src/content/management/Events/statistics/referenced-rrpp')));
const StatisticsEventGift = Loader(lazy(() => import('src/content/management/Events/statistics/gift')));
const StatisticsEventLoyalty = Loader(lazy(() => import('src/content/management/Events/statistics/loyalty')));
const Users = Loader(lazy(() => import('src/content/management/Users')));
const EditUser = Loader(lazy(() => import('src/content/management/Users/edit')));
const Roles = Loader(lazy(() => import('src/content/management/Roles')));
const CreateRol = Loader(lazy(() => import('src/content/management/Roles/create')));
const EditRol = Loader(lazy(() => import('src/content/management/Roles/edit')));
const Notifications = Loader(lazy(() => import('src/content/management/Notifications')));
const CreateNotification = Loader(lazy(() => import('src/content/management/Notifications/create')));
const EditNotification = Loader(lazy(() => import('src/content/management/Notifications/edit')));
const News = Loader(lazy(() => import('src/content/management/News')));
const CreateNew = Loader(lazy(() => import('src/content/management/News/create')));
const EditNew = Loader(lazy(() => import('src/content/management/News/edit')));
const Statistics = Loader(lazy(() => import('src/content/management/Statistics')));
const Tags = Loader(lazy(() => import('src/content/management/Tags')));
const CreateTag = Loader(lazy(() => import('src/content/management/Tags/create')));
const EditTag = Loader(lazy(() => import('src/content/management/Tags/edit')));
const Status401 =  Loader(lazy(() => import('src/content/pages/Status/Status401')));
const TermsAndConditions = Loader(lazy(() => import('src/policies/tyc-v2')));
const PrivacyPolicie = Loader(lazy(() => import('src/policies/privacy-policy')));

const managementRoutes = [
  {
    path: 'events',
    children: [
      {
        path: '',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS]} element={<Navigate to="list" replace />} />
        )
      },
      {
        path: 'list',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS]} element={<Events />} />
        )
      },
      {
        children: [
          {
            path: '',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS]} element={<Events />} />
            )
          },
          {
            path: 'create',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<CreateEvent />} />
            )
          },
          {
            path: 'edit',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<EditEvent />} />
            )
          },
          {
            path: 'tickets',
            children: [
              {
                path: '',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS]} element={<Navigate to="list" replace />} />
                )
              },
              {
                path: 'list',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS]} element={<Tickets />} />
                )
              },
              {
                path: 'create',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS]} element={<CreateTicket />} />
                )
              },
              {
                path: 'redimidor',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS]} element={<ManagementTicketsRedimidor />} />
                )
              },
              {
                path: 'sell-ticket',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.POS]} element={<SellTicket />} />
                )
              },
              {
                path: 'sell',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.POS]} element={<SellCompleted />} />
                )
              },
            ]
          },
          {
            path: 'ticketsTypes',
            children: [
              {
                path: '',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<Navigate to="list" replace />} />
                )
              },
              {
                path: 'list',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<TicketsTypes />} />
                )
              },
              {
                path: 'create',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<CreateTicketTypes />} />
                )
              }
            ]
          },
          {
            path: 'statistics',
            children: [
              {
                path: '',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.POS, rolesApp.PRODUCTOR]} element={<Navigate to="list" replace />} />
                )
              },
              {
                path: 'list',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.POS, rolesApp.PRODUCTOR]} element={<StatisticsEvent />} />
                )
              },
              {
                path: 'direct-rrpp',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<StatisticsEventDirectRRPP />} />
                )
              },
              {
                path: 'producer',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<StatisticsEventProducer />} />
                )
              },
              {
                path: 'referenced-rrpp',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<StatisticsEventReferencedRRPP />} />
                )
              },
              {
                path: 'gift',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<StatisticsEventGift />} />
                )
              },
              {
                path: 'loyalty',
                element: (
                  <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR]} element={<StatisticsEventLoyalty />} />
                )
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'unauthorized',
    element: <Status401 />
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
        )
      },
      {
        path: 'list',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<Users />} />
        )
      },
      {
        path: 'edit',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<EditUser />} />
        )
      }
    ]
  },
  {
    path: 'roles',
    children: [
      {
        path: '',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
        )
      },
      {
        path: 'list',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<Roles />} />
        )
      },
      {
        children: [
          {
            path: '',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
            )
          },
          {
            path: 'create',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<CreateRol />} />
            )
          },
          {
            path: 'edit',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<EditRol />} />
            )
          }
        ]
      }
    ]
  },
  {
    path: 'notifications',
    children: [
      {
        path: '',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.PRODUCTOR, rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
        )
      },
      {
        path: 'list',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.PRODUCTOR, rolesApp.SUPERADMIN]} element={<Notifications />} />
        )
      },
      {
        children: [
          {
            path: '',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.PRODUCTOR, rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
            )
          },
          {
            path: 'create',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.PRODUCTOR, rolesApp.SUPERADMIN]} element={<CreateNotification />} />
            )
          },
          {
            path: 'edit',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.PRODUCTOR, rolesApp.SUPERADMIN]} element={<EditNotification />} />
            )
          }
        ]
      }
    ]
  },
  {
    path: 'news',
    children: [
      {
        path: '',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
        )
      },
      {
        path: 'list',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN]} element={<News />} />
        )
      },
      {
        children: [
          {
            path: '',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
            )
          },
          {
            path: 'create',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN]} element={<CreateNew />} />
            )
          },
          {
            path: 'edit',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN]} element={<EditNew />} />
            )
          }
        ]
      }
    ]
  },
  {
    path: 'statistics',
    children: [
      {
        path: '',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.PRODUCTOR, rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
        )
      },
      {
        path: 'list',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.PRODUCTOR, rolesApp.SUPERADMIN]} element={<Statistics />} />
        )
      }
    ]
  },
  {
    path: 'tags',
    children: [
      {
        path: '',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
        )
      },
      {
        path: 'list',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<Tags />} />
        )
      },
      {
        children: [
          {
            path: '',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
            )
          },
          {
            path: 'create',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<CreateTag />} />
            )
          },
          {
            path: 'edit',
            element: (
              <RoleBasedRoute rolesCustom={[rolesApp.ADMIN, rolesApp.SUPERADMIN]} element={<EditTag />} />
            )
          }
        ]
      }
    ]
  },
  {
    path: 'sell',
    children: [
      {
        path: '',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.POS, rolesApp.SUPERADMIN]} element={<Navigate to="list" replace />} />
        )
      },
      {
        path: 'choose',
        element: (
          <RoleBasedRoute rolesCustom={[rolesApp.POS, rolesApp.SUPERADMIN]} element={<Sell />} />
        )
      }
    ]
  },
  {
    path: 'tyc-v2',
    element: <TermsAndConditions />
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicie />
  },
  {
    path: 'privacy-policie',
    element: <PrivacyPolicie />
  },
  {
    path: 'tyc',
    element: <PrivacyPolicie />
  },
];

export default managementRoutes;
